.speech-search-bar {
  padding: 2px;
  width: 90px; /* Make them equal width */
  font-size: 14px;
  outline: none;
}

.speech-search-bar {
  width: 60%;
  background-color: ghostwhite;
  color: black;
  border-radius: 6px;
}

.ImproveHere {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.createArticleContainer {
  width: 100%;
  text-align: center;
}

.toggleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Space between button and expanded content */
}

.expandedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.toggleButton:hover .arrowIcon {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.createArticleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.create_article_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.createArticleForm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
}

.articleInput {
  width: 80%;
  padding: 10px 15px;
  border-radius: 30px;
  border: 2px solid #3A5BA0; /* Change this to your preferred color */
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
}

.articleInput::placeholder {
  color: #aaa;
}

.submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius: 50%;
  border: none;
  background-color: #d3d3d3; /* Change this to your preferred color */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #c0c0c0; /* Change this to your preferred hover color */
}

.submitIcon {
  font-size: 18px;
  color: #555; /* Change this to your preferred color */
}


.language-selector-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the select box and the button */
  padding: 10px;
  margin-right: 20%;
}

.language-select {
  margin-right: 30%;
  padding: 10px 15px;
  border-radius: 0px;
  border: 1px solid #dee2e9; /* Adjust the border color as needed */
  font-size: 16px;
  outline: none;
  background-color: #f9f9f9;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.language-select:hover {
  background-color: #f1f1f1;
  border-color: #2854a1;
}

.listen-button {
  padding: 10px 20px;
  border-radius: 0px;
  border: 2px solid #3578E5; /* Border matches the select box */
  background-color: #3578E5;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.listen-button:hover {
  background-color: #2854a1; /* Darker shade on hover */
  border-color: #1c3b73;
}
