@tailwind base;
@tailwind components;
@tailwind utilities;
#root{
    /*overflow: auto;*/
    /*fix the root page*/
    height: 100vh;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
}

.arco-tabs-content {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding-top: 8px;
    box-sizing: border-box;
}
/*
control the style of audio panel (in audioBlob component)
if the browser doesn't support this css, the penal will be default, whatever, audio always playable
*/
#audio-player::-webkit-media-controls-panel{
    background-color: white;
}
